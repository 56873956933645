import { Override } from "framer"
import { useEffect, useState } from "react"

export function Twitter(): Override {
    return {
        onTap: () => {
            let pageTitle =
                document.title.replace("Ashley Smith Properties | ", "") + ""
            const tweetText = `${encodeURIComponent(
                pageTitle
            )}%0A%0A${encodeURIComponent(window.location.href)}`
            const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`
            window.open(twitterUrl, "_blank")
        },
        text: "Share on Twitter",
    }
}

export function Facebook(): Override {
    return {
        onTap: () => {
            let pageTitle = document.title.replace(
                "Ashley Smith Properties | ",
                ""
            )
            const shareUrl = encodeURIComponent(window.location.href)
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodeURIComponent(
                pageTitle
            )}`
            window.open(facebookUrl, "_blank")
        },
        text: "Share on Facebook",
    }
}

export function LinkedIn(): Override {
    return {
        onTap: () => {
            const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                window.location.href
            )}`
            window.open(linkedinUrl, "_blank")
        },
        text: "Share on LinkedIn",
    }
}

export function Email(): Override {
    return {
        onTap: () => {
            let pageTitle = document.title.replace("Carbon Direct | ", "") + ""
            const mailUrl = `mailto:?subject=${encodeURIComponent(
                pageTitle
            )}&body=${encodeURIComponent(window.location.href)}`
            window.open(mailUrl, "_blank")
        },
        text: "Email Link",
    }
}

export function Clipboard(): Override {
    const [copySuccess, setCopySuccess] = useState(false)

    useEffect(() => {
        let timeout
        if (copySuccess) {
            timeout = setTimeout(() => {
                setCopySuccess(false)
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [copySuccess])

    return {
        onTap: async () => {
            try {
                await navigator.clipboard.writeText(window.location.href)
                setCopySuccess(true)
            } catch (err) {
                console.error("Failed to copy: ", err)
            }
        },
        text: copySuccess ? "Copied!" : "Copy URL",
    }
}
